<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <n-grid
            :label="$t('Stores.allStores')"
            :src="`api/v1/public/entities/${$route.params.entidadId || 'System'}/stores`"
            :filters="{
              'entidad': filters.entidad
            }"
            :filter-operators="{
              'entidad': 'like'
            }"
          >
            <template #append-header="{ isLoading }">
              <v-toolbar-items>
                <v-text-field v-model="filters.entidad" :placeholder="$t('AppBar.search')" outlined rounded dense
                  clearable hide-details single-line prepend-inner-icon="mdi-magnify" :disabled="isLoading"></v-text-field>
              </v-toolbar-items>
            </template>
          
            <template #item="{ item, on }">
              <base-card
                class="primary"
                @click="() => $router.push(`/entities/${item.entidadId}`).catch(() => {})"
              >
                <v-img height="250" :src="item.imagen2 || '/img/detault_store_image_2.png'" cover>
                  <div class="ma-5">
                    <h3 class="white--text mb-0">
                      {{ item.entidad }}
                    </h3>

                    <div class="d-flex align-start mb-2 mt-6">
                      <v-icon small class="mr-2" color="white">
                        mdi-map-marker
                      </v-icon>
                      <span class="white--text">{{ item.domicilio || "..." }}</span>
                    </div>

                    <div class="d-flex align-start mb-2">
                      <v-icon small class="mr-2" color="white">
                        mdi-phone
                      </v-icon>
                      <span class="white--text">{{ item.telefono || "..." }}</span>
                    </div>

                    <div class="d-flex align-start mb-2">
                      <v-icon small class="mr-2" color="white">
                        mdi-mail
                      </v-icon>
                      <span class="white--text">{{ item.email }}</span>
                    </div>
                  </div>
                </v-img>
              </base-card>
            </template>
          </n-grid>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      filters: {},
    };
  }
};
</script>