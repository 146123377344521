var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('n-grid',{attrs:{"label":_vm.$t('Stores.allStores'),"src":("api/v1/public/entities/" + (_vm.$route.params.entidadId || 'System') + "/stores"),"filters":{
            'entidad': _vm.filters.entidad
          },"filter-operators":{
            'entidad': 'like'
          }},scopedSlots:_vm._u([{key:"append-header",fn:function(ref){
          var isLoading = ref.isLoading;
return [_c('v-toolbar-items',[_c('v-text-field',{attrs:{"placeholder":_vm.$t('AppBar.search'),"outlined":"","rounded":"","dense":"","clearable":"","hide-details":"","single-line":"","prepend-inner-icon":"mdi-magnify","disabled":isLoading},model:{value:(_vm.filters.entidad),callback:function ($$v) {_vm.$set(_vm.filters, "entidad", $$v)},expression:"filters.entidad"}})],1)]}},{key:"item",fn:function(ref){
          var item = ref.item;
          var on = ref.on;
return [_c('base-card',{staticClass:"primary",on:{"click":function () { return _vm.$router.push(("/entities/" + (item.entidadId))).catch(function () {}); }}},[_c('v-img',{attrs:{"height":"250","src":item.imagen2 || '/img/detault_store_image_2.png',"cover":""}},[_c('div',{staticClass:"ma-5"},[_c('h3',{staticClass:"white--text mb-0"},[_vm._v(" "+_vm._s(item.entidad)+" ")]),_c('div',{staticClass:"d-flex align-start mb-2 mt-6"},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"white"}},[_vm._v(" mdi-map-marker ")]),_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(item.domicilio || "..."))])],1),_c('div',{staticClass:"d-flex align-start mb-2"},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"white"}},[_vm._v(" mdi-phone ")]),_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(item.telefono || "..."))])],1),_c('div',{staticClass:"d-flex align-start mb-2"},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"white"}},[_vm._v(" mdi-mail ")]),_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(item.email))])],1)])])],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }